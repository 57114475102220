<template>
  <div>
    <div class="form-action">
      <!-- filter desktop -->
      <div class="filter-desktop business-account-history">
        <!-- filter 1 -->
        <b-row class="d-flex justify-content-end input-control my-2">
          <b-col
            md="4"
            lg="3"
            class="pr-md-0"
          >
            <div
              data-testid="filter-status-transaction-container"
              class="status-input"
            >
              <v-select
                v-model="statusSelected"
                label="name"
                :options="statusOptions"
                :clearable="false"
                @input="handleStatusChange"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="20"
                    />
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
          <b-col
            md="5"
            class="input-height mt-1 mt-md-0"
          >
            <calendar
              v-model="rangeDate"
              :placeholder="placeholder"
              @input="handleDateChange"
            />
          </b-col>
        </b-row>
      </div>
      <!-- /filter desktop -->
    </div>

    <!-- table -->
    <vue-good-table
      data-testid="business-account-history-table"
      class="vgt-no-border"
      mode="remote"
      :columns="columns"
      :rows="histories"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: total > 0,
      }"
      :sort-options="{
        enabled: total > 0,
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-column"
      >
        <span
          v-if="props.column.field === 'invoice'"
          class="d-flex justify-content-between align-items-center"
        >
          <div class="d-flex align-items-start">
            <p class="mb-0">{{ props.column.label }}</p>
            <feather-icon
              v-if="guideToDownloadInvoiceTopUp"
              id="tooltip-learn-user-guide"
              icon="FileTextIcon"
              size="16"
              class="ml-25 text-success"
              style="min-width: 16px"
            />
            <b-tooltip
              target="tooltip-learn-user-guide"
              triggers="hover"
              variant="light"
            >
              How to download invoice?
              <a
                target="_blank"
                :href="guideToDownloadInvoiceTopUp"
              >{{ $t('payment.balanceAllocationTable.textViewDetail') }}</a>
            </b-tooltip>
          </div>
        </span>
      </template>

      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ad Account -->
        <div
          v-if="props.column.field === 'createdAt'"
          data-testid="business-account-transaction-date"
          class="d-flex flex-column"
        >
          <span>{{ createdDate(props.row.createdAt) }}</span>
        </div>

        <!-- Column: transaction type -->
        <span
          v-else-if="props.column.field === 'type'"
          data-testid="business-account-transaction-type"
          class="text-nowrap"
        >
          {{ transactionTxt(props.row.type) }}
        </span>

        <!-- Column: status -->
        <span
          v-else-if="props.column.field === 'status'"
          data-testid="business-account-transaction-status"
          class="text-nowrap text-capitalize"
        >
          <b-badge
            v-if="props.row.status === 'mining'"
            variant="light-warning"
            class="font-14"
          >
            {{ $t('payment.textPending') }}
          </b-badge>
          <b-badge
            v-else
            :variant="statusVariant(props.row.status)"
            class="font-14"
          >
            {{ statusTxt(props.row.status) }}
          </b-badge>
        </span>

        <!-- Column: Balance -->
        <span v-else-if="props.column.field === 'amount'">
          <div class="d-flex flex-column">
            <span
              v-if="props.row.operator !== 'none'"
              data-testid="text-amount"
              :class="amountColor(props.row.operator)"
            >{{
              `${props.row.operator}${amountFormat(props.row.amount)}`
            }}</span>
            <span v-else>
              {{ amountFormat(props.row.amount) }}
            </span>
            <span
              v-if="feeTxt(props.row)"
              data-testid="text-fee"
              class="text-placeholder font-small-1 d-flex align-items-center"
            >
              {{ $t('payment.balanceTransactionsTable.textFee') }}
              <feather-icon
                v-b-tooltip.hover.html.v-light="totalFee(props.row)"
                icon="InfoIcon"
                size="12"
                data-testid="icon-info-fee"
                class="icon-info cursor-pointer"
                style="margin-left: 2px"
              />
            </span>
          </div>
        </span>

        <span v-else-if="hasLocalBank && props.column.field === 'transferCode'">
          <div data-testid="transfer-code">
            <span
              v-if="props.row.localBankSessionCode"
              data-testid="text-transfer-code"
              class="font-weight-600 text-error"
            >{{ props.row.localBankSessionCode }}</span>
          </div>
        </span>

        <span v-else-if="props.column.field === 'note'">
          <div data-testid="description-reason">
            <span
              v-if="props.row.payload && props.row.payload.description"
              data-testid="text-description-reason"
              class="font-weight-600 text-error"
            >{{ props.row.payload.description }}</span>
          </div>
        </span>

        <div v-else-if="canDownLoad(props)">
          <b-img
            data-testid="download-invoice"
            class="cursor-pointer"
            fluid
            :src="require('@/assets/images/icons/ic-download-invoice.svg')"
            @click="openModal(props.row)"
          />
        </div>

        <!-- Column: common -->
        <span
          v-else
          data-testid="transaction-id"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="total > 0"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap mt-1 footer-tab"
          :class="[
            { 'justify-content-center': $device.mobile },
            { 'justify-content-between': !$device.mobile },
          ]"
        >
          <!-- page length -->
          <div class="d-flex align-items-center mb-0">
            <span
              data-testid="total-business-account-transaction"
              class="font-medium font-14"
            >{{ total }} {{ $t('payment.AdsAccountHistoryTable.textHistoryTransaction') }}</span>
          </div>
          <div v-if="total > pageLength">
            <b-pagination
              :total-rows="total"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              data-testid="pagination-business-account-transaction"
              class="mb-0 mt-1 mt-md-0 pagination-table"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <!-- no account image -->
      <div
        slot="emptystate"
        class="text-center"
      >
        <no-history-placeholder
          :img="require('@/assets/images/common/no-data-table.svg')"
          class="my-5"
        />
      </div>
      <!-- /no account image -->
    </vue-good-table>
    <!-- /table -->

    <!-- download pdf -->
    <download-top-up-pdf-modal
      ref="modal-topup-download"
      :invoice-data="inVoiceSelected"
    />
    <download-top-up-pdf-modal-pati
      ref="modal-topup-download-pati"
      :invoice-data="inVoiceSelected"
    />
  </div>
</template>
<script>
import {
  BPagination,
  BBadge,
  BSpinner,
  VBTooltip,
  BRow,
  BCol,
  BImg,
  VBModal,
  BTooltip,
} from 'bootstrap-vue'

// libs & cons
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { createNamespacedHelpers } from 'vuex'
import Calendar from '@/components/Calendar.vue'

// mixins
import tableAccountHistoryMixin from '@/mixins/tableAccountHistoryMixin.vue'
import { TRANSACTION_TYPE, TRANSACTION_STATUS, PAYMENT_METHOD } from '@/constants'
import { bus } from '@/plugins/bus'
import Ripple from 'vue-ripple-directive'
import envMixin from '@/mixins/envMixin'
import paymentMixin from '@/mixins/paymentMixin'
import NoHistoryPlaceholder from './NoHistoryPlaceholder.vue'
import DownloadTopUpPdfModal from '../Modal/DownloadTopUpPdfModal.vue'
import DownloadTopUpPdfModalPati from '../Modal/DownloadTopUpPdfModalPati.vue'

const {
  mapGetters,
  mapActions,
} = createNamespacedHelpers('payment')
export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    DownloadTopUpPdfModal,
    DownloadTopUpPdfModalPati,
    NoHistoryPlaceholder,
    BBadge,
    BPagination,
    BRow,
    BCol,
    BImg,
    BSpinner,
    VueGoodTable,
    BTooltip,
    vSelect,
    Calendar,
  },
  mixins: [tableAccountHistoryMixin, envMixin, paymentMixin],
  data() {
    return {
      placeholder: this.$t('payment.AdsAccountHistoryTable.placeholderSelectDate'),
      rangeDate: {
        startDate: null,
        endDate: null,
      },
      inVoiceSelected: {},
    }
  },

  computed: {
    ...mapGetters([
      'listBusinessAccountHistory',
      'message',
      'status',
      'loading',
    ]),

    columns() {
      return [
        {
          label: 'ID',
          field: '_id',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('balance.textAmount'),
          field: 'amount',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('payment.AdsAccountHistoryTable.transactionTypeColumn'),
          field: 'type',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('account.status'),
          field: 'status',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('payment.balanceTransactionsTable.transferCode'),
          field: 'transferCode',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('account.note'),
          field: 'note',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-reason-reject',
        },
        {
          label: this.$t('payment.AdsAccountHistoryTable.transactionDateColumn'),
          field: 'createdAt',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('payment.balanceTransactionsTable.textDownloadInvoice'),
          field: 'invoice',
          thClass: `p-1 border-left-0 border-right-0 header-table-mf ${this.hasShowInvoice && !this.isExclusivePartner ? '' : 'd-none'}`,
          tdClass: `p-1 border-left-0 border-right-0 align-middle ${this.hasShowInvoice && !this.isExclusivePartner ? '' : 'd-none'}`,
          sortable: false,
        },
      ]
    },

    histories() {
      return this.listBusinessAccountHistory.content
    },
    total() {
      return this.listBusinessAccountHistory.paging.total || 0
    },

    feeTxt() {
      return data => data.type === TRANSACTION_TYPE.RECHARGE_USER_BALANCE && data.payload
    },

    totalFee() {
      return transaction => {
        const { paymentMethod } = transaction
        let paymentMethodName = ''

        switch (paymentMethod) {
          case PAYMENT_METHOD.CREDITCARD:
            paymentMethodName = 'Credit card'
            break
          case PAYMENT_METHOD.TRANSFERWISE:
            paymentMethodName = 'Wise'
            break
          case PAYMENT_METHOD.AIRWALLEX_AUTO:
            paymentMethodName = 'AirWallex'
            break
          case PAYMENT_METHOD.PAYONEER_TICKET:
            if (this.hasPayoneer) {
              paymentMethodName = 'Payoneer ticket'
            } else {
              paymentMethodName = 'Payoneer'
            }
            break
          case PAYMENT_METHOD.LOCALBANK:
            paymentMethodName = 'Local bank'
            break
          case PAYMENT_METHOD.LIANLIAN:
            paymentMethodName = 'LianLian'
            break
          case PAYMENT_METHOD.PAYPAL:
            paymentMethodName = 'PayPal'
            break
          case PAYMENT_METHOD.IDEAL:
            paymentMethodName = 'iDEAL'
            break
          case PAYMENT_METHOD.BANCONTACT:
            paymentMethodName = 'Bancontact'
            break
          default:
            paymentMethodName = paymentMethod
        }

        let txt = '<div class="text-left font-14">'
        if (transaction?.payload?.serviceCharge !== undefined) {
          txt += `${this.$t('payment.balanceTransactionsTable.textServiceCharge')}: $${this.amountFormat(transaction?.payload?.serviceCharge)} <br/>`
        }

        txt += `${this.$t('payment.balanceTransactionsTable.textMethodFee', { paymentMethodName })}: $${this.amountFormat(transaction?.payload?.paymentGateCharge) || 0}<br/>
        ${this.$t('payment.balanceTransactionsTable.textTax')}: $${transaction?.payload?.taxCharge || 0}<br/>`

        if (transaction?.payload?.tipAMAmount > 0) {
          txt += `${this.$t('payment.balanceTransactionsTable.textTip')}: $${transaction?.payload?.tipAMAmount} <br/>`
        }

        txt += '</div>'

        return txt
      }
    },

    canDownLoad() {
      return data => data.column.field === 'invoice'
        && data.row.status === TRANSACTION_STATUS.DONE
        && (data.row.type === TRANSACTION_TYPE.RECHARGE_USER_BALANCE
          || data.row.type === TRANSACTION_TYPE.ADMIN_FIX_RECHARGE_MONEY)
    },

    rechargeAdsAccount() {
      return data => data?.payload?.rechargeAdsAccount && data?.payload?.rechargeAdsAccount?.status !== 'done'
    },
  },

  created() {
    this.fetchHistory()

    bus.$on('refresh-table-history', () => {
      this.resetData()
    })
  },

  methods: {
    ...mapActions(['getHistory']),
    async fetchHistory() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        types: [
          TRANSACTION_TYPE.RECHARGE_USER_BALANCE,
          TRANSACTION_TYPE.ADMIN_FIX_RECHARGE_MONEY,
          TRANSACTION_TYPE.ADMIN_FIX_WITHDRAW_MONEY,
          TRANSACTION_TYPE.USER_TIP_FOR_AM,
          TRANSACTION_TYPE.CASHBACK,
          TRANSACTION_TYPE.SETUP_COST,
        ],
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.sortTerm.field
          }`,
        }),
        ...(this.searchTerm && { keyword: this.searchTerm }),
        ...this.rangeDates,
        ...(this.statusSelected.value && { status: this.statusSelected.value }),
      }
      await this.getHistory({
        type: 'business',
        params,
      })
    },

    resetData() {
      this.currentPage = 1
      this.searchTerm = ''

      this.sortTerm = {
        field: '',
        type: '',
      }

      this.pageLength = 10
      this.statusSelected = {
        name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
        value: '',
      }

      this.rangeDate = null
      this.startDate = null
      this.endDate = null
      this.dateSelected = null

      this.fetchHistory()
    },

    openModal(data) {
      this.inVoiceSelected = data
      if (this.isPATIPlatform) {
        this.$refs['modal-topup-download-pati'].openModal()
      } else {
        this.$refs['modal-topup-download'].openModal()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-mobile {
  padding: 0 20px;
}

.business-account-history {
  padding: 0 16px;
}

.input-height {
  height: 40px;
}

.footer-tab {
  padding: 0 1rem 1rem;
}

.reason {
  width: 300px;

  &-message {
    border: 1px solid #e0e0e5;
    border-radius: 4px;
    padding: 10px;
    margin-top: 5px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.input-control {
  .vs__dropdown-toggle {
    height: 40px;
  }
}

.td-reason-reject {
  min-width: 200px;
}

</style>
